import React, { useRef } from 'react';

import Image from '../components/Image';
import Container from '../components/Container';
import Layout from '../components/Layout/Layout';

import * as styles from './gallery.module.css';

const GalleryPage = (props) => {
  const images = [
    { src: '/pool_reline_after_1.jpeg', height: "320px"}, 
    { src: '/pool_aboveground_after_2.jpeg', height: "320px"},
    { src: '/harmonAfter.jpeg', height: "320px"},
    { src: '/pool_aboveground_after_1.jpeg', height: "320px"},
    { src: '/pool_reline_after_4.jpeg', height: "320px"},
  ];

  const doughboyImages = [
    { src: '/gallery/Hugo_1_Before.jpeg', height: "420px", captionTitle: "Before"}, 
    { src: '/gallery/Hugo_2_After.jpeg', height: "420px", captionTitle: "After", caption: "Liner pattern: Coventry / Prism"}, 
    { src: '/gallery/Harmon_1_Before.jpeg', height: "460px", captionTitle: "Before"},
    { src: '/gallery/Harmon_2_After.jpeg', height: "460px", captionTitle: "After", caption: "Liner pattern: Stonebraid / Royal Prism"},
    { src: '/gallery/Lane_1_Before.jpeg', height: "360px", captionTitle: "Before"},
    { src: '/gallery/Lane_2_After.jpeg', height: "360px", captionTitle: "After", caption: "Liner pattern: Coventry / Prism"},
    // { src: '/gallery/Kirkpatrick_1_Before.jpeg', height: "360px", captionTitle: "Before"},
    // { src: '/gallery/Kirkpatrick_2_After.jpeg', height: "360px", captionTitle: "After", caption: "Liner Pattern: Royal / Blue Mosaic"},
  ];

  const endlessImages = [
    { src: '/gallery/endless_3.jpeg', height: "320px"}, 
    { src: '/gallery/endless_2.jpeg', height: "320px"}, 
    { src: '/gallery/endless_4.jpeg', height: "320px"},
    { src: '/gallery/endless_7.jpeg', height: "320px"},
    { src: '/gallery/endless_5.jpeg', height: "320px"},
    { src: '/gallery/endless_6.jpeg', height: "320px"},
  ];

  const ingroundVinylImages = [
    { src: '/gallery/inground_vinyl_liner.png', height: "320px"}, 
    { src: '/gallery/inground_vinyl_liner2.jpeg', height: "320px"}, 
    { src: '/gallery/inground_vinyl_liner3.jpeg', height: "300px"},
    { src: '/gallery/inground_vinyl_liner4.jpeg', height: "300px"},
  ];

  const remodelImages = [
    { src: '/gallery/remodel1.jpeg', height: "320px"}, 
    { src: '/gallery/remodel2.jpeg', height: "320px"}, 
  ];

  const fiberglassResurfaceImages = [
    { src: '/gallery/fiberglass_resurface1.jpeg', height: "320px"}, 
    { src: '/gallery/fiberglass_resurface2.jpeg', height: "320px"}, 
  ];

  const renderImageGrid = (images) => {
    return (
      <div className={styles.galleryGrid}>
        {images.map(image => {
          return (<div className={styles.image}><Image height={image.height} src={image.src} captionTitle={image.captionTitle} caption={image.caption}></Image></div>);
        })}
      </div>
    );
  };
  
  return (
    <Layout>
      <Container>
        <h1 className={styles.heading}>Gallery</h1>
        <div className={styles.gallerySectionTitle} data-fade-in-section>Vinyl liner replacement (Doughboy pools)</div>
        {renderImageGrid(doughboyImages)}
        <div className={styles.gallerySectionTitle} data-fade-in-section>Inground vinyl liner pools</div>
        {renderImageGrid(ingroundVinylImages)}
        <div className={styles.gallerySectionTitle} data-fade-in-section>Above-ground Pools</div>
        {renderImageGrid(images)}
        <div className={styles.gallerySectionTitle} data-fade-in-section>Endless Pools</div>
        {renderImageGrid(endlessImages)}
      </Container>
    </Layout>
  );
};

export default GalleryPage;
